import React from "react"
import Image from "gatsby-image"
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { Container } from "./styles"

interface LoopImagesSectionProps {
  title: string
  images: any[]
  visibleSlides?: number
  interval?: number
  showDots?: boolean
}

const LoopImagesSection: React.FC<LoopImagesSectionProps> = ({
  images,
  title,
  visibleSlides,
  interval,
  showDots,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <h2>{title}</h2>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={images.length}
        visibleSlides={visibleSlides || 5}
        infinite={true}
        isPlaying={true}
        interval={interval || 500}

      >
        <Slider>
          {images.map((image, key) => (
            <Slide key={key} index={key}>
              <Image fluid={image} />
            </Slide>
          ))}
        </Slider>
        {showDots && <DotGroup showAsSelectedForCurrentSlideOnly />}
      </CarouselProvider>
    </Container>
  )
}

export default LoopImagesSection
