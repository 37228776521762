import styled from "styled-components"

import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1120px;
  margin: 60px auto;
  padding: 0 30px;

  .carousel {
    height: 120px;
    width: 100%;
    padding-top: 20px;

    text-align: center;

    .carousel__slider {
      height: 120px;
    }

    .carousel__dot-group button {
      margin: 0 5px;
      padding: 0;
      border: none;
      background-color: #ccc;
      border-radius: 50%;
      width: 10px;
      height: 10px;

      &.carousel__dot--selected {
        background-color: ${myTheme.colors.primary};
      }
    }

    img {
      width: 80% !important;
      height: 80% !important;
      filter: grayscale(100%);

      &:hover {
        filter: grayscale(0%);
      }

      cursor: grab;
    }
  }

  @media(max-width: 700px) {
    ul {
      width: 650% !important;
    }
  }
`
