import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LoopImagesSection from "../LoopImagesSection"

const CompaniesSection: React.FC = ({
  ...rest
}) => {
  const {
    twoshareLogo: {
      childImageSharp: { fluid: twoshareLogo },
    },
    maristarLogo: {
      childImageSharp: { fluid: maristarLogo },
    },
    universalLogo: {
      childImageSharp: { fluid: universalLogo },
    },
    zobooLogo: {
      childImageSharp: { fluid: zobooLogo },
    },
    coqueiralLogo: {
      childImageSharp: { fluid: coqueiralLogo },
    },
    rogeriolucenaLogo: {
      childImageSharp: { fluid: rogeriolucenaLogo },
    },
    redefreitasLogo: {
      childImageSharp: { fluid: redefreitasLogo },
    },
    fastnutriLogo: {
      childImageSharp: { fluid: fastnutriLogo },
    },
    agbrazilLogo: {
      childImageSharp: { fluid: agbrazilLogo },
    },
    aldolopesadvogadosLogo: {
      childImageSharp: { fluid: aldolopesadvogadosLogo },
    },
    colegioedimLogo: {
      childImageSharp: { fluid: colegioedimLogo },
    },
    flogisticaLogo: {
      childImageSharp: { fluid: flogisticaLogo },
    },
    gamatelLogo: {
      childImageSharp: { fluid: gamatelLogo },
    },
    gardoLogo: {
      childImageSharp: { fluid: gardoLogo },
    },
    ipbpcLogo: {
      childImageSharp: { fluid: ipbpcLogo },
    },
    livrariahrLogo: {
      childImageSharp: { fluid: livrariahrLogo },
    },
    nobrearquiteturaLogo: {
      childImageSharp: { fluid: nobrearquiteturaLogo },
    },
    pezinhoLogo: {
      childImageSharp: { fluid: pezinhoLogo },
    },
  } = useStaticQuery(images)
  return (
    <LoopImagesSection
        title="Empresas Que Já Atendemos"
        images={[
          fastnutriLogo,
          pezinhoLogo,
          maristarLogo,
          universalLogo,
          coqueiralLogo,
          rogeriolucenaLogo,
          redefreitasLogo,
          livrariahrLogo,
          flogisticaLogo,
          agbrazilLogo,
          zobooLogo,
          gamatelLogo,
          ipbpcLogo,
          gardoLogo,
          nobrearquiteturaLogo,
          twoshareLogo,
          aldolopesadvogadosLogo,
          colegioedimLogo,
        ]}
        visibleSlides={5}
        interval={3000}
        {...rest}
      />
  )
}

const images = graphql`
query {
  twoshareLogo: file(
    relativePath: { eq: "clientes/2share.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  maristarLogo: file(
    relativePath: { eq: "clientes/maristar.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  fastnutriLogo: file(
    relativePath: { eq: "clientes/fast-nutri.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  universalLogo: file(
    relativePath: { eq: "clientes/universal.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  zobooLogo: file(
    relativePath: { eq: "clientes/zoboo.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  coqueiralLogo: file(
    relativePath: { eq: "clientes/coqueiral.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  rogeriolucenaLogo: file(
    relativePath: { eq: "clientes/rogerio-lucena.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  redefreitasLogo: file(
    relativePath: { eq: "clientes/rede-freitas.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  agbrazilLogo: file(
    relativePath: { eq: "clientes/ag-brazil.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  aldolopesadvogadosLogo: file(
    relativePath: { eq: "clientes/aldolopes-advogados.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  colegioedimLogo: file(
    relativePath: { eq: "clientes/colegio-edim.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  flogisticaLogo: file(
    relativePath: { eq: "clientes/flogistica.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  gamatelLogo: file(
    relativePath: { eq: "clientes/gamatel.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  gardoLogo: file(
    relativePath: { eq: "clientes/gardo.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  ipbpcLogo: file(
    relativePath: { eq: "clientes/ipbpc.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  livrariahrLogo: file(
    relativePath: { eq: "clientes/livraria-hr.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  nobrearquiteturaLogo: file(
    relativePath: { eq: "clientes/nobre-arquitetura.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  pezinhoLogo: file(
    relativePath: { eq: "clientes/pezinho.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  },
  visualodontoLogo: file(
    relativePath: { eq: "clientes/visual-odonto.png" }
  ) {
    childImageSharp {
      fluid(maxHeight: 137) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default CompaniesSection
